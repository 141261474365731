var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"39.12.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import * as SentryIntegrations from '@sentry/integrations'

declare global {
  interface Window {
    _env_: any
  }
}
Sentry.init({
  dsn: window._env_.REACT_APP_SENTRY_DSN_REACT,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  release: window._env_.REACT_APP_VERSION,
  environment: window._env_.REACT_APP_SENTRY_ENV || 'not-set',
  normalizeDepth: 10,
  ignoreErrors: [
    // Ignore known errors
    'Object Not Found Matching Id'
  ],

  integrations: [
    // Add Sentry debug only for development environment: This prints Sentry events in console
    ...(window._env_.NODE_ENV === 'development' ? [new SentryIntegrations.Debug()] : []),

    // Custom depth for extra info in extra info objects in errors
    new SentryIntegrations.ExtraErrorData({ depth: 10 }),

    // Add session time info to events
    new SentryIntegrations.SessionTiming(),
    //new SentryIntegrations.Transaction()
  ]
})